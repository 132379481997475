
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: "Footer",
  setup() {
    const address1ActivetoRef = ref('bj')
    const address2ActivetoRef = ref('xa')
    const lookAddress = (address: string, type: number) => {
      if(type === 1){
        address1ActivetoRef.value = address
      } else if(type === 2) {
        address2ActivetoRef.value = address
      }
    }
    return { address1ActivetoRef, address2ActivetoRef, lookAddress };
  },
});
