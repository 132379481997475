
import { defineComponent, reactive } from "vue";
import { useRouter } from 'vue-router'
export default defineComponent({
  name: "Solution",
  setup() {
    const router = useRouter()
    const state = reactive({
      navlist: ['智慧管廊', '智慧管线', '智慧供热', '智慧轨道', '智慧水务'],
      solutionActive: 0,
      solutionlist: [
        {
          imgUrl: require('../assets/img/solution/solution-1.png'),
          title: '智慧管廊解决方案',
          detail: '率先提出UTDTM管廊数字孪生体模型，对综合管廊工程物理特征、功能信息和周边地上地下环境进行数字化承载和可视化表达，汇聚管廊多源大数据，支撑管廊态势感知与营运管控，达到“图-数-模”多维同步，覆盖管廊全业务应用与服务，实现综合管廊建管一体化、地下地上一体化、平战一体化的全生命周期智能化管理。',
        },
        {
          imgUrl: require('../assets/img/solution/solution-2.png'),
          title: '智慧管线解决方案',
          detail: '以管线管理为导向，以管线数据为核心，以管线更新为重点，以物联感知为手段，面向管线规划设计、建设施工、权属运维、行业监管人员，建立多层次、安全可靠、动态实用的管线综合信息平台，辅助用户建立科学长效的管线数据动态更新管理机制，有利于管线安全与防灾减灾，提升地下管线精细化管理水平。',
        },
        {
          imgUrl: require('../assets/img/solution/solution-3.png'),
          title: '智慧供热解决方案',
          detail: '服务于各级供热监管部门对区域内供热企业的运营监管、质量监督、应急指挥和热能效评估，为供热整体规划、供热网络优化、供热政策制定提供决策依据。以智能感知、全网运营、精确分析、快速响应、精确计量、全渠道沟通，为热力企业可持续发展构建核心竞争力，助力企业绿色节能、运营高效、服务高质。',
        },
        {
          imgUrl: require('../assets/img/solution/solution-4.png'),
          title: '智慧轨道解决方案',
          detail: '基于轨道交通安全生产及设备技术状态大数据分析平台，以设施设备及主要关键部件全生命周期管理为目标，深化数据综合应用，实现设备故障规律分析、设备技术状态评价、人员巡检作业跟踪、运输安全风险预警和安全趋势分析，为调度指挥、运营管理、维修养护、应急救援、决策支持提供技术支撑。',
        },
        {
          imgUrl: require('../assets/img/solution/solution-5.png'),
          title: '智慧水务解决方案',
          detail: '构建给排水物联网络，实现厂-站-网一张图，实时感知给排水系统运行状态，外业巡检与内业监管有机融合，满足供（污）水厂、泵站、管网精细化管理需求，运用管网水力模型、DMA漏损计算模型、曝气与加药模型和水量预测模型，预测未来用量，助力节能降耗，赋能水环境管理。',
        }
      ]
    })
    const navChanged = (i: number) => {
      state.solutionActive = i;
    };
    const lookdetail = () => {
      console.log(state.solutionActive)
      router.push({ path: '/Services/' + state.solutionActive })
    }
    return { state, navChanged, lookdetail };
  },
});
