import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import lazyPlugin from 'vue3-lazy'

const app = createApp(App);
app.use(lazyPlugin, {
  loading: require('@/assets/img/home/icon_job_bg.png'), // 加载时默认图片
  error: require('@/assets/img/home/icon_job_bg.png')// 图片失败时默认图片
})
app.use(router).mount("#app");
