import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47bbc7f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "swiper-container" }
const _hoisted_2 = { class: "swiper-titlebox" }
const _hoisted_3 = { class: "titlebox-le" }
const _hoisted_4 = { class: "titlebox-ri" }
const _hoisted_5 = { class: "entit-box" }
const _hoisted_6 = { class: "firstletter" }
const _hoisted_7 = { class: "entit" }
const _hoisted_8 = { class: "title" }
const _hoisted_9 = { class: "detail" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_swiper, {
      pagination: { "dynamicBullets": true },
      loop: true,
      autoplay: {
        "delay": 5000,
        "disableOnInteraction": false
      }
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.states.imglist, (item, i) => {
          return (_openBlock(), _createBlock(_component_swiper_slide, { key: i }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["swiper-bg", 'banner' + i])
              }, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(item.title1), 1),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("p", _hoisted_5, [
                      _createElementVNode("span", _hoisted_6, _toDisplayString(item.entitle1), 1),
                      _createElementVNode("span", _hoisted_7, _toDisplayString(item.entitle2), 1)
                    ]),
                    _createElementVNode("p", _hoisted_8, _toDisplayString(item.title2), 1),
                    _createElementVNode("p", _hoisted_9, _toDisplayString(item.detail), 1)
                  ])
                ])
              ], 2)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ]))
}