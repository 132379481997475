
import { defineComponent} from "vue";
import { useRouter } from 'vue-router'
export default defineComponent({
  name: "Production",
  setup() {
    const router = useRouter()
    const lookdetail = () => {
      router.push({ path: '/AboutUs' })
    }
    return { lookdetail };
  },
});
