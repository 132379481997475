
import { reactive, defineComponent, onMounted, provide, ref } from "vue";
// import { useRoute } from 'vue-router'
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
export default defineComponent({
  name: "App",
  components: {
    Header,
    Footer
  },
  setup() {
    const scrollTop = ref(0)
    const isTop = ref(false)
    provide('scrollTop', scrollTop)
    onMounted(() => {
      window.onscroll = () => {
        onScrollfun()
      };
    });
    const state = reactive({
      includeList: ['Home', 'AboutUs', 'News', 'JoinUs']
    })
    const onScrollfun = () =>{
      scrollTop.value =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop.value === 0) {
        isTop.value = true;
      } else {
        isTop.value = false;
      }
    }
    // const route = useRoute()
    // watch(() => route,(newVal, oldVal)=>{
    //   if(newVal.meta.keepAlive && state.includeList.indexOf(newVal.name) === -1){
    //     state.includeList.push(newVal.name);
    //     console.log(state.includeList);
    //   }
    // },{deep:true}) // 开启深度监听
    onScrollfun() // 进入就判断一次
    return { scrollTop, isTop, state };
  },
});
