import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    meta: {
      keepAlive: true
    },
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    meta: {
      keepAlive: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/Products/:id",
    name: "Products",
    meta: {
      keepAlive: false //设置页面是否需要使用缓存
    },
    component: () =>
      import("../views/products.vue"),
  },
  {
    path: "/Services/:id",
    name: "Services",
    meta: {
      keepAlive: false
    },
    component: () =>
      import("../views/services.vue"),
  },
  {
    path: "/AboutUs",
    name: "AboutUs",
    meta: {
      keepAlive: true
    },
    component: () =>
      import("../views/aboutUs.vue"),
  },
  {
    path: "/News",
    name: "News",
    meta: {
      keepAlive: true
    },
    component: () =>
      import("../views/news.vue"),
  },
  {
    path: "/JoinUs",
    name: "JoinUs",
    meta: {
      keepAlive: true
    },
    component: () =>
      import("../views/joinUs.vue"),
  },
  {
    path: "/JobDetail/:id",
    name: "JobDetail",
    meta: {
      keepAlive: false
    },
    component: () =>
      import("../views/jobsDetail.vue"),
  },
  {
    path: "/NewsDetail/:id",
    name: "NewsDetail",
    meta: {
      keepAlive: false
    },
    component: () =>
      import("../views/newsDetail.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    return { left: 0, top: 0 }
  }
});

export default router;
