import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, { isTop: _ctx.isTop }, null, 8, ["isTop"]),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component, route }) => [
        _createVNode(_Transition, {
          name: route.meta.transitionName
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_KeepAlive, {
              include: _ctx.state.includeList
            }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ], 1032, ["include"]))
          ]),
          _: 2
        }, 1032, ["name"])
      ]),
      _: 1
    }),
    _createVNode(_component_Footer)
  ], 64))
}