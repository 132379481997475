
import { defineComponent, reactive } from "vue";
import { useRouter } from 'vue-router'
export default defineComponent({
  name: "ProductService",
  setup() {
    const router = useRouter()
    const states = reactive({
      productDetail: [
        {
          entit: 'IGC-V',
          title: "可视化平台",
          detail:
            "平台在重资产行业三维可视化领域十年磨一剑，支持全域、全景、全程业务深度应用，多专业、多模式、多粒度呈现无缝融合，结合丰富的模型制作、BIM轻量化、激光扫描、倾斜摄影等数据生产和治理经验，为用户提供易用、实用、管用的可视化平台全套解决方案，支撑数字孪生产业应用。",
          iconurl: require("../assets/img/productsAndServices/product-icon1.png"),
          imgurl: require("../assets/img/productsAndServices/product-1.png"),
        },
        {
          entit: 'IGC-A',
          title: "资产管理平台",
          detail:
            "平台依照集约化管理、基建/生产一体化管理模式，将设备全生命周期履历作为设备资产维护的基础知识库，提供资产台账、运行状态、巡检记录、养修工单、备件库存、技术图档的关联查看及一站式维护。融合动设备诊断研判、预防式动态维护、作业安全分析评价、成本自动归集核算，助力设备安全生产与降本增效。",
          iconurl: require("../assets/img/productsAndServices/product-icon3.png"),
          imgurl: require("../assets/img/productsAndServices/product-3.png"),
        },
        {
          entit: 'IGC-T',
          title: "物联网平台",
          detail:
            "平台兼容各类主流传输协议，提供行业标准协议以及设备私有协议接入等多种接入方式，具备强大的对象模型定义、协议转换适配和规则引擎驱动能力，一站式、免代码、拖拽式的二三维可视化组态开发，帮助用户灵活多变的完成专业水准的物联网可视化应用搭建，同时满足个性化业务需求，提升物联网数据应用价值。",
          iconurl: require("../assets/img/productsAndServices/product-icon2.png"),
          imgurl: require("../assets/img/productsAndServices/product-2.png"),
        },
        {
          entit: 'IGC-E',
          title: "应急管理平台",
          detail:
            "平台日常可用于隐患排查、应急值守、资源准备和数字预案，基于HLA的应急联动仿真模拟，提供城市级、区域级、装置级的应急模拟演练；战时可多系统联机协同，支持远程会商和指挥调度，成为应急资讯的汇集点、预测预警的智能库、抢险救援的控制台，让应急过程中的信息链、决策链和处置链高效运转。",
          iconurl: require("../assets/img/productsAndServices/product-icon4.png"),
          imgurl: require("../assets/img/productsAndServices/product-4.png"),
        },
      ],
    });
    const lookdetail = (i: number) => {
      console.log(i)
      router.push({ path: '/Products/' + i })
    }
    return { states, lookdetail };
  },
});
