
import { defineComponent } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.css";
import SwiperCore, { Pagination, Autoplay } from "swiper";
SwiperCore.use([Pagination, Autoplay]);
export default defineComponent({
  name: "swiper1",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const states = {
      imglist: [
        {
          title1: "智",
          title2: "周万物 增效提质",
          entitle1: "I",
          entitle2: "ntellectualization",
          detail: '全业务、全流程、全生命周期，为城市运营注入新动能',
        },
        {
          title1: "图",
          title2: "破界限 现元知真",
          entitle1: "G",
          entitle2: "raphical",
          detail: '空、天、地，为信息传呈提供新维度',
        },
        {
          title1: "云",
          title2: "联融合 协作赋能",
          entitle1: "C",
          entitle2: "loudization",
          detail: '共享、共治、共赢，为数字转型带来新思路',
        }
      ],
    };
    return { states };
  },
});
